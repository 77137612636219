import React, { Fragment  } from 'react'
import PropTypes from 'prop-types'
import Img from "gatsby-image"
import { graphql, Link }  from 'gatsby'
import compose from 'lodash/fp/flowRight'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div'

import H4 from '../components/H4'
import Body2 from './../components/Body2'

import { withSolutions } from '../components/SolutionProvider'

import { titleForm } from '../components/styles/InternalPage.styles'
import { 
  termosBox
} from '../components/styles/Contact.styles'
import CloudServerForm from '../components/ContactForm2'
import { validateRequiredEmail } from '../utils/validations';
import Paragraph from '../components/Paragraph'
const fields = [
  {
    label: 'Selecione',
    name: 'item',
    type: 'select',
    options: [
      { value: 1, label: 'Phishing' },
      { value: 2, label: 'Child Exploitation' },
      { value: 3, label: 'Malware' },
      { value: 4, label: 'SPAM' },
      { value: 5, label: 'Outbound Attacks' },
      { value: 6, label: 'Subpoena' },
      { value: 7, label: 'DMCA / Copyright Infringement' },
      { value: 8, label: 'Unauthorized Use of Personal Content' }
    ],
    required: true
  },
  {
    label: 'Email',
    name: 'your-email',
    type: 'email',
    required: true,
    validate: validateRequiredEmail,
  },
  {
    label: 'IP',
    name: 'your-ip',
    type: 'text',
    required: true,
    mask: [' ', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, ' ']
  },
  {
    label: 'URL', 
    name: 'your-url',
    type: 'text',
    required: true
  },
  {
    label: 'Empresa',
    name: 'your-company',
    type: 'text',
    required: true
  },
  {
    label: 'Mensagem',
    name: 'your-message',
    type: 'text',
    multiline: true,
    rows: 6,
    required: true
  },
  {
    label: (
      <span css={termosBox}>
        Ao continuar, indica que aceita os
        {" "}
        <Link to="/termos-de-servico" style={{ textDecoration: 'underline' }}>
          Termos de Serviço
        </Link>
        {" "}
        e a nossa
        {" "}
        <Link to="/politica-de-privacidade" style={{ textDecoration: 'underline' }}>
          Política de Privacidade.
        </Link>
      </span>
    ),
    name: 'your-consent',
    type: 'checkbox',
    required: true
  },
]

export const PageTemplate = ({
  data,
  ...rest
}) => {
  return (
    <Fragment>
      <section id='quero-testar'>
        <Div
          px={[16, 16, 24, 24]}
          alignItems={['center']}
        >
          <Div
            maxWidth={['100%', 390, 855, 1086]}
          >
            <Row
              flexDirection={['column', 'row']}
              mt={[34.5, 35, 70, 70]}
              mx={[-16, -16, 0, 0]}
              alignItems={['center', 'end']}
            >
              <Column
                width={[1, 1, 1, 1/2]}
                display={['none', 'none', 'flex']}
                style={{
                  justifyContent: 'center'
                }}
              >
                <Div
                  maxWidth={['100%', '100%', 400, 450]}
                >
                  <Img
                    fluid={data.image2.childImageSharp.fluid}
                    loading='eager'
                  />
                </Div>
              </Column>
              <Column
                width={[1, 1, 5/6, 1/2]}
              >
                <Div
                  alignItems={['center', 'center', 'flex-start']}
                  textAlign={['center', 'center', 'left']}
                  maxWidth={['100%', 422, 400, 400]}
                >
                  <H4
                    color='black'
                    variant='highEmphasis'
                    mb={[5, 5, 3]}
                    css={titleForm}
                  >
                    Abuse Report
                  </H4>
                  <Div
                    display={['block', 'block', 'none']}
                    mb={[5]}
                    maxWidth={[310, 310, 327, 360]}
                  >
                    <Img
                      fluid={data.image2.childImageSharp.fluid}
                      loading='eager'
                    />
                  </Div>
                  <Body2
                    selected
                    on='onSurface'
                    variant='mediumEmphasis'
                    order={[2, 2, 1]}
                    mb={[0, 0, 3]}
                    maxWidth={['100%', 422, 416, 416]}
                    alignItems={['center', 'center', 'normal', 'normal']}
                  >
                    <Paragraph>Preencha o formulário abaixo caso você tenha identificado alguma atividade maliciosa - como spams, malwares, phishing - vinda de serviços hospedados em nossos servidores.</Paragraph> <br />
                    
                    <Paragraph>Em nossos <Link to='termos-de-servico'>Termos de Serviço</Link>, está explícito que o controle e identificação dessas atividades fica sob responsabilidade do contratante. Todavia, o compromisso de garantir a segurança e bem-estar de todas as pessoas que usufruem de nossos serviços nos faz reforçar que repudiamos tais ações e disponibilizamos esse espaço para auxílio, investigação e eliminação de atividades maliciosas.</Paragraph>
                    <Div
                      style={{
                        marginTop: 40,
                        marginBottom: 70,
                        textAlign: 'center'
                      }}
                    >
                      <CloudServerForm
                        fields={fields}
                        formId={4528}
                        submitText='Enviar abuse report'
                      />
                    </Div>
                  </Body2>
                </Div>
              </Column>
            </Row>
          </Div>
        </Div>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object,
  values: PropTypes.object
}

const StaticPage = (props) => {
  return (
    <Layout>
      <Seo 
        title='HostDime | Abuse Report'
        description='Identificou alguma atividade suspeita vinda de serviços hospedados em nossos servidores? Reporte aqui!'
      />
      <PageTemplate
        title='Abuse Report'
        {...props}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image2: file(relativePath: { eq: "support/abuse-report.png" }) {
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const enhance = compose(
  withSolutions
)

export default enhance(StaticPage)
